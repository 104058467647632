:root {
	--standard-button-border-radius: 100px;
	--standard-hover-opacity: 0.5;
	--higher-hover-opacity: 0.8;
}

.standard-button-border-radius {
	border-radius: var(--standard-button-border-radius);
}

.standard-hover-opacity {
	opacity: 1;
	transition-property: opacity;
	transition-duration: 0.2s;
}

.standard-hover-opacity:hover {
	opacity: var(--standard-hover-opacity);
}

.higher-hover-opacity {
	opacity: 1;
	transition-property: opacity;
	transition-duration: 0.2s;
}

.higher-hover-opacity:hover {
	opacity: var(--higher-hover-opacity);
}
