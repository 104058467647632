/* Colors of the application should be added here to be reusable
 * REMEMBER to always add a new color to both root AND create a new class below
*/

/* Use var(--color-blabla) in CSS-files */

:root {
	--color-primary-white: #f9f7f6;
	--color-primary-blue: #1e9ede;
	--color-primary-red: red;
	--color-secondary-red: rgb(211, 50, 50);
	--color-text-white: #ffffff;
	--color-text-black: #000000;
	--color-text-gray: #acacac;
}

.bg-color-primary-white {
	background-color: var(--color-primary-white);
}

.color-primary-white {
	color: var(--color-primary-white);
}

.bg-color-primary-blue {
	background-color: var(--color-primary-blue);
}

.color-primary-blue {
	color: var(--color-primary-blue);
}

.color-primary-blue {
	color: var(--color-primary-blue);
}

.bg-color-primary-red {
	background-color: var(--color-primary-red);
}

.color-primary-red {
	color: var(--color-primary-red);
}
.color-secondary-red {
	color: var(--color-secondary-red);
}
.color-text-white {
	color: var(--color-text-white);
}
.color-text-black {
	color: var(--color-text-black);
}
.color-text-gray {
	color: var(--color-text-gray);
}
