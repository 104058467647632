@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.striped div:nth-child(odd) {
		@apply bg-gray-100;
	}
}

@font-face {
	font-family: "RedHatText";
	src: url("../fonts/RedHatText-Regular.ttf");
}

html {
}
body {
	font-family: "RedHatText";
	font-weight: 400;
	height: 100%;
	margin: 0;
	display: flex;
	flex-direction: column;
}

.cursor-hover:hover {
	cursor: pointer;
}

.standard-button {
	color: var(--color-primary-white);
	font-size: 1em;
	font-weight: 600;
	min-width: 133px;
	padding: 5px 10px;
}

.primary-button {
	background-color: var(--color-primary-blue);
}

.secondary-button {
	background-color: var(--color-secondary-red);
}

.search-button {
	background-color: var(--color-primary-blue);
	color: var(--color-primary-white);
}

.standard-input-field {
	box-sizing: border-box;
	border: rgb(183, 183, 183) 1px solid;
}

.search-input-field {
	box-sizing: border-box;
	border: white 1px solid;
	outline: none;
}

.login-form {
	box-shadow: 2px 2px 7px #a9a9a9;
	border-radius: 2px;
}

.error-box {
	background-color: #f1f1f1;
}

.category-box {
	position: absolute;
	padding: 5px 15px 5px 30px;
	background: #adffff;
	/*Angled edge*/
	mask: linear-gradient(-135deg, #fff 50%, transparent 50%) top left;
	mask-size: 2000px 2000px;
	/* width = height and big enough to consider all the cases */
}

.sponsor-label {
	position: absolute;
	padding: 5px;

	background: #63ff8a;
}

.edit-icon {
	position: absolute;
	padding: 5px 2px 0px 15px;
}

.header {
	height: 60vh;
	min-height: 550px;
}

.video-background {
	width: 100%;
	margin: auto;
	background-color: var(--color-primary-blue);
}

.video-header {
	max-width: 1280px;
	margin: auto;
	background-color: var(--color-primary-blue);
}

.small-header {
	height: 40vw;
	min-height: 250px;
}

.ad-container {
	margin: auto;
	width: auto;
	max-height: 150px;
	display: flex;
	justify-content: center;
}

.ad-container-small {
	margin: auto;
	max-width: 1000px;
}

.card-grid {
	padding-top: 20px;
	justify-content: start;
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: 55vh;
	background: var(--color-primary-blue);
}

.card-grid-nocolor {
	padding-top: 20px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: 55vh;
}
